<template>
    <ValidationObserver ref="inputAdd"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(saveSettings)" @reset.prevent="reset">
            <ValidationProvider name="label" vid="label" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="label"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                        {{$t('component_settings.label')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                    id="label"
                    v-model="inputFields.label"
                    :placeholder="$t('component_settings.label_input')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="help_text" vid="help_text">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="help_text"
                >
                    <template v-slot:label>
                        {{$t('component_settings.help_text')}}
                    </template>
                    <b-form-input
                    id="help_text"
                    v-model="inputFields.help_text"
                    :placeholder="$t('component_settings.help_text_input')"
                    ></b-form-input>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="value" vid="value">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="value"
                >
                    <template v-slot:label>
                        {{$t('component_settings.value')}}
                    </template>
                    <b-form-input
                    id="value"
                    v-model="inputFields.value"
                    @input="changeQRCode"
                    ></b-form-input>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="qr_code" vid="qr_code">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="qr_code"
                >
                    <template v-slot:label>
                        {{$t('component_settings.qr_code')}}
                    </template>
                    <vue-qrcode :value="qr_code" />
                </b-form-group>
            </ValidationProvider>
            <b-button type="submit" variant="primary">{{ 'Save'}}</b-button>
        </b-form>
    </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { wordsToSnake } from '@/Utils/fliter'
import VueQrcode from 'vue-qrcode'
export default {
    props: ['editData', 'formData'],
    components: {
        ValidationObserver,
        ValidationProvider,
        VueQrcode
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            qr_code: '',
            inputFields: {
                id: 1,
                isRequired: false,
                is_show: false,
                value: '',
                label: '',
                field_name: '',
                field_type: 'qr_code',
                help_text: ''
            },
            formTitle: ''
        }
    },
    created () {
        if (this.editData) {
            this.inputFields = this.editData
        }
    },
    watch: {
        'inputFields.label': function (newVal) {
            this.inputFields.field_name = this.wordsToSnake(newVal)
            this.formTitle = newVal
        }
    },
    methods: {
        wordsToSnake (val) {
            return wordsToSnake(val)
        },
        saveSettings: function () {
            const tmp = JSON.parse(JSON.stringify(this.inputFields))
            this.$emit('addQRCode', tmp, 'modal-11')
        },
        changeQRCode () {
            this.qr_code = this.inputFields.value
        }
    }
}
</script>
